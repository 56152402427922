import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/Layout';

const NotFound = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <h1>404</h1>
            <h2>Seems like we may have a loose connection...</h2>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFound;
